<template>
  <div>
    <v-menu transition="slide-y-transition" bottom offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="text-light" color="primary" v-bind="attrs" v-on="on">
          Actions
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, i) in actionItems"
          :key="i"
          link
          @click="triggerAction(item.clickFunc)"
        >
          <v-icon>{{ item.icon }}</v-icon> &nbsp;
          <v-list-item-title>{{ item.title }} </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-dialog
      v-model="apApplicationSelectionDialog"
      max-width="600px"
      @click:outside="toggleSelectionDialog(false)"
    >
      <v-card>
        <v-toolbar :color="HRDC_BLUE_THEME_COLOR" dark>
          Export Selected Clients Bank Details
        </v-toolbar>

        <v-card-title>
          <p style="font-size: 14px; line-height: 20px">
            Once you clicked confirmd you can export selected clients bank
            details
            <br />
            and Finance Refund to Client date will update to the selected
            clients.
          </p>
        </v-card-title>
        <v-form lazy-validation>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="apSelectedDate"
                        label="Date"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="apSelectedDate"
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-spacer></v-spacer>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green lighten-1"
              class="text-light"
              @click="showRefundClientForm()"
            >
              Confirm
            </v-btn>
            <v-btn
              color="red lighten-1"
              class="text-light"
              @click="toggleSelectionDialog(false)"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Api from "@/objects/api";
import { mapState } from "vuex";
import Service from "@/objects/service";
import hrdcFunction from "@/objects/hrdcFunction";
import HrdcData from "@/services/hrdc/objects/globalData";

export default {
  mixins: [hrdcFunction],
  name: "ApApplicationSelection",
  data: () => ({
    api: new Api(),
    service: new Service(),
    isLoading: false,
    apSelectedDate: new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10),
    menu: false,
    modal: false,
    menu2: false,
    hrdcData: new HrdcData(),
    actionItems: [
      {
        title: "Refund Client",
        clickFunc: "toggleSelectionDialog",
        icon: "mdi-cash",
      },
      {
        title: "Download Excel",
        clickFunc: "downloadExcel",
        icon: "mdi-microsoft-excel",
      },
      {
        title: "Previous Record",
        clickFunc: "prevRecord",
        icon: "mdi-calendar-search",
      },
    ],
  }),
  computed: mapState({
    auth: (state) => state.auth.data,
    services: (state) => state.service.data.services,
  }),
  props: {
    apApplicationSelectionDialog: {
      type: Boolean,
      required: true,
    },
    apiUrl: {
      type: String,
      required: true,
    },
  },
  methods: {
    showRefundClientForm() {
      var selectedDate = this.apSelectedDate;
      this.$emit("showRefundClientForm", selectedDate);
    },
    toggleSelectionDialog(value) {
      this.$emit("toggleSelectionDialog", value);
    },
    triggerAction(value) {
      switch (value) {
        case "toggleSelectionDialog":
          this.$emit("toggleSelectionDialog", true);
          break;
        case "downloadExcel":
          this.$emit("downloadExcel");
          break;
        case "prevRecord":
          this.$emit("prevRecord");
          break;
      }
    },
  },
};
</script>
